import Vue from "vue";
import Vuex from "vuex";

let dom = window.location.hostname;
let url = "https://blogverse.educatum.tech/";
let site = "https://blogverse.educatum.tech/";

if (dom == "localhost") {
  url = "http://127.0.0.1:8000/";
}
if (dom == "localhost") {
  site = "http://127.0.0.1:8000/";
}
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    baseURL: url + "api/",
    siteURL: site,
    imagesBaseURL: url,

    baseImageURL: url,
    // baseImageURL: 'https://hanifjewelers.forsaerp.com/uploads/',
    acesstoken: null,
  },
  mutations: {},
  actions: {},
  modules: {},
});
