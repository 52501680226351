import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import CatCarousel from "vue-cat-carousel";
import axios from "axios";
import VueAxios from "vue-axios";
import VueMeta from "vue-meta";
// import { CKEditor  } from "@ckeditor/ckeditor5-vue/dist/ckeditor";
import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use(VueMeta);
Vue.use(require("vue-moment"));
Vue.use(VueAxios, axios);
Vue.use(CatCarousel);
Vue.use(Vuetify);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use( CKEditor );

// Vue.use(CKEditor);
const token = localStorage.getItem("user-token");
if (token) {
  // window.axios.defaults.headers.common = {
  //     'Authorization': 'Bearer ' + token
  // };
  axios.defaults.headers.common["Authorization"] = token;
}

export const bus = new Vue();
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Content-Type"] = "application/json";
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
