<template>
  <div>
  <div class="container-fluid py-3">
      <div class="container">
          <div class="row">
              <div class="col-lg-8">
                  <!-- News Detail Start -->
                  <div class="position-relative mb-3">
                      <img class="img-fluid w-100" :src="baseImageURL + image" style="object-fit: cover;">
                      <div class="overlay position-relative bg-light">
                          <div class="mb-3">
                              <a href="">{{ category }}</a>
                              <span class="px-1">/</span>
                              <span>{{ create_date }}</span>
                          </div>
                          <div>
                              <h3 class="mb-3">
                                {{ title }}
                              </h3>
                              <p v-html="content"> </p>
                          </div>
                      </div>
                  </div>
                  <!-- News Detail End -->
              </div>
<Sidebar/>
          </div>
      </div>
  </div>
  
<NewsLetter/>
  
  </div>
</template>
<script>
import axios from "axios";
import Sidebar from "./Sidebar.vue";
export default {
  name: "post",
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        { name: "description", content: this.description },
        { name: "title", content: this.meta },
      ],
      link: [{ rel: "canonical", href: this.current_url }],
    };
  },
  data(baseImageURL = "") {
    return {
      posts: [],
      post: null,
      description: null,
      meta: null,
      title: null,
      current_url: null,
      post_slug: "",
      baseImageURL: baseImageURL,
    };
  },

  components: {

    Sidebar
  },
  mounted() {
    
    this.post_slug = this.$route.params.slug;
    this.baseImageURL = this.$store.state.baseImageURL;
 
    // this.items = JSON.parse(localStorage.getItem('items'));
    const vm = this;
    axios
      .post(vm.$store.state.baseURL + "post/"+vm.post_slug, { 
        headers: {},
      })
      .then(function (response) {
        console.log(response);
        vm.post = response.data.data;
        vm.title = response.data.data.title;
        vm.image = response.data.data.feature_image;
        vm.create_date = response.data.data.created_at;
        vm.content = response.data.data.content;
        vm.category = response.data.data.category.name;
        vm.description = response.data.data.meta_description;
        vm.meta = response.data.data.meta;
        // vm.loader = 0;
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          // vm.loader = 0;
        }
      });
  },
  created() {
    this.current_url = this.$store.state.siteURL + this.$route.path;
  },
};
</script>
