<template>
    <div class="container-fluid">
        <div class="container">
            <div class="content-post">
                <b-row class="text-center mt-4"> </b-row>
                <div class="row">
                    <div
                            class="col-lg-4 col-md-6"
                            v-for="(item, k) in subcategory"
                            :key="k"
                    >
                        <div
                                class="content-post-wrapper auto-height bg-color1"
                                style="border-radius: 4%"
                        >
                            <div class="content-post-details">
                                <!--                                <ul class="details-meta list-inline">-->
                                <!--                                    <li class="list-inline-item">-->
                                <!--                                        <router-link :to="'/subcategory/'+item.slug">-->
                                <!--                                            {{item.name}}-->
                                <!--                                        </router-link>-->
                                <!--                                    </li> -->
                                <!--                                </ul>-->
                                <h5>
                                    <router-link :to="'/subcategory/' + item.slug">{{
                                        item.name
                                        }}</router-link>
                                </h5>
                                <!--                                <div class="details-author">-->
                                <!--                                    <div class="details-author-admin">-->
                                <!--                                        {{item.user_name.name}}-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                            </div>
                            <div class="content-post-img">
                                <router-link :to="'/subcategory/' + item.slug">
                                    <b-img
                                            style="max-height: 300px; min-height: 300px"
                                            thumbnail
                                            rounded
                                            class="i"
                                            :src="baseImageURL + item.image"
                                            fluid
                                            alt="Responsive image"
                                    ></b-img>
                                </router-link>
                            </div>
                            <div class="text-center mb-2 mt-2">
                                {{ item.created_at | moment("dddd, MMMM Do YYYY") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "axios";

    export default {
        name: "Home",
        metaInfo() {
            return {
                title: "Category - " + this.category,
                meta: [
                    { charset: "utf-8" },
                    { name: "description", content: this.description },
                    { name: "title", content: this.meta },
                ],
                link: [{ rel: "canonical", href: this.current_url }],
            };
        },
        data(baseImageURL = "") {
            return {
                description: "defualt description",
                meta: "defualt meta",
                current_url: "/",
                category: "",
                subcategory: [],
                baseImageURL: baseImageURL,
            };
        },
        components: {},
        mounted() {
            this.current_url = this.$store.state.siteURL + this.$route.path;
            this.category = this.$route.params.slug;

            this.baseImageURL = this.$store.state.baseImageURL;
            // this.items = JSON.parse(localStorage.getItem('items'));
            const vm = this;
            axios
                .post(vm.$store.state.baseURL + "category/" + this.category + "/post",  {
                    // category: vm.category,
                    headers: {},
                })
                .then(function (response) {
                    console.log(response);
                    vm.subcategory = response.data.data;
                    // vm.title = response.data.data.title;
                    vm.description = response.data.data.meta_description;
                    vm.meta = response.data.data.meta;
                    vm.loader = 0;
                })
                .catch((error) => {
                    if (error) {
                        console.log(error);
                        // vm.loader = 0;
                    }
                });
        },
    };
</script>
