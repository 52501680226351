import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import layout from "../views/layout.vue";
 
import Post from "../views/post.vue";
 
import Page404 from "../views/Page404.vue";
import category from "../views/category.vue";
import subcategory from "../views/subcategory.vue";
import categorypost from "../views/categorypost.vue";
import login from "../views/login.vue";
import BlogDetail from "../views/BlogDetail.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import LoginView from "../views/LoginView.vue";
import RegisterView from "../views/RegisterView.vue";
import ContactView from "../views/ContactView.vue";
import AboutView from "../views/AboutView.vue";
//   import DashboardView from "../views/admin_dashboard/DashboardView.vue";
// import CreateBlogsView from "../views/admin_dashboard/CreateBlogsView.vue";
  import MainLayout from "../views/admin_dashboard/MainLayout.vue";

Vue.use(VueRouter);

const routes = [
   
    
    {
        path: "/admin",
        redirect: "/admin",
        name: "Home2",
        component: MainLayout,
        children: [
            {
                path: "/dashboardys",
                name: "dashboardys",
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/admin_dashboard/DashboardView.vue"),
            },
            {
                path: "/create-blog",
                name: "blog creation",
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/admin_dashboard/CreateBlogsView.vue"),
            },
            {
                path: "/manage-blogs",
                name: "manage blogs",
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/admin_dashboard/ManageBlogs.vue"),
            },
        ]
    },

    
   
    {
        path: "/",
        redirect: "/",
        name: "Home",
        component: layout,
        children: [
            {
                path: "/about",
                name: "about",
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/About.vue"),
            },
            

            {
                path: "/contact",
                name: "contact",
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/contact.vue"),
            },
            {
                path: "/",
                name: "homeapage",
                component: Home,
            },
            {
                path: "/post/:slug",
                name: "post",
                component: Post,
            },
            {
                path: "/category/:slug",
                name: "category",
                component: category,
            }, {
                path: "/categories",
                name: "categories",
                component: category,
            },
            {

                path: "/category/:slug/post",
                name: "category_post",
                component: categorypost,
            },
            {
                path: "/subcategory/:slug",
                name: "subcategory",
                component: subcategory,
            },
            {
                path: '/subcategories/:categoryId',
                name: 'subcategories',
                component: subcategory
            },

            {
                path: '/blogdetail/',
                name: 'blogdetail',
                component: BlogDetail

            },
            {
                path: '/privacy-policy/',
                name: 'privacypolicy',
                component: PrivacyPolicy

            },
            {
                path: '/contact2/',
                name: 'contact2',
                component: ContactView,
            },
            {
                path: '/about2/',
                name: "about2",
                component: AboutView
            },
            
            

        ]

    },
    {
        path: "/login",
        component: login,
        beforeEnter: (to, from, next) => {
            ifAuthenticatednot(to, from, next);
        },
    },

    
    
    {
        path: '/login2/',
        name: 'login',
        component: LoginView,
    },
    {
        path: '/register/',
        name: 'register',
        component: RegisterView,
    },

   
    

    {path: "*", component: Page404},
];
// const ifAuthenticated = (to, from, next) => {
//     const token = localStorage.getItem("user-token");
//     if (token) {
//         next();
//     } else {
//         next("/login");
//     }
// };
const ifAuthenticatednot = (to, from, next) => {
    const token = localStorage.getItem("user-token");
    if (!token) {
        next();
    } else {
        next("/admin/dashboardys");
    }
};

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
