<template>
    <div class="  ">

        <!-- Top News Slider Start -->
        <div class="container-fluid py-3">
            <div class="container">
                <div class="owl-carousel owl-carousel-2 carousel-item-3 position-relative">


                    <div v-for="item in post" :key="item.id" class="d-flex">
                        <img :src="baseImageURL + item.feature_image"
                            style="width: 80px; height: 80px; object-fit: cover;">
                        <div class="d-flex align-items-center bg-light px-3" style="height: 80px;">
                            <a class="text-secondary font-weight-semi-bold" :href="'post/'+ item.slug">{{ item.title }}</a>
                        </div>
                    </div>


                </div>
            </div>
            <!-- Top News Slider End -->


            <!-- Main News Slider Start -->
            <div class="container-fluid py-3">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class=" position-relative mb-3 mb-lg-0">
                                <div :key="post_single.id" class="position-relative overflow-hidden" style="height: 435px;">
                                    <img class="img-fluid h-100"  :src="baseImageURL + post_single.feature_image"
                                        style="object-fit: cover;min-width:100%">
                                    <div class="overlay">
                                        <div class="mb-1">
                                            <a class="text-white" href="">Technology</a>
                                            <span class="px-2 text-white">/</span>
                                            <a class="text-white" href="">{{ post_single.created_at }}</a>
                                        </div>
                                        <router-link :to="'post/'+post_single.slug">
                                            <h2 class="m-0 text-white font-weight-bold">{{ post_single.title }}</h2>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-4">
                            <div class="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                                <h3 class="m-0">Categories</h3>
                                <router-link to="/categories"
                                    class="text-secondary font-weight-medium text-decoration-none">View All</router-link>
                            </div>

                            <div v-for="category in categories" :key="category.id"
                                class="position-relative overflow-hidden mb-3" style="height: 80px;">
                                <img class="img-fluid w-100 h-100" :src="baseImageURL + category.feature_image"
                                    style="object-fit: cover;">
                              <router-link :to="'category/'+ category.slug" 
                                    class="overlay align-items-center justify-content-center h4 m-0 text-white text-decoration-none">
                                    {{ category.name }}
                                </router-link >
                            </div>
                            <!--                            <div class="position-relative overflow-hidden mb-3" style="height: 80px;">-->
                            <!--                                <img class="img-fluid w-100 h-100" src="/newtemplate/img/cat-500x80-2.jpg"-->
                            <!--                                     style="object-fit: cover;">-->
                            <!--                                <a href=""-->
                            <!--                                   class="overlay align-items-center justify-content-center h4 m-0 text-white text-decoration-none">-->
                            <!--                                    Technology-->
                            <!--                                </a>-->
                            <!--                            </div>-->
                            <!--                            <div class="position-relative overflow-hidden mb-3" style="height: 80px;">-->
                            <!--                                <img class="img-fluid w-100 h-100" src="/newtemplate/img/cat-500x80-3.jpg"-->
                            <!--                                     style="object-fit: cover;">-->
                            <!--                                <a href=""-->
                            <!--                                   class="overlay align-items-center justify-content-center h4 m-0 text-white text-decoration-none">-->
                            <!--                                    Entertainment-->
                            <!--                                </a>-->
                            <!--                            </div>-->
                            <!--                            <div class="position-relative overflow-hidden" style="height: 80px;">-->
                            <!--                                <img class="img-fluid w-100 h-100" src="/newtemplate/img/cat-500x80-4.jpg"-->
                            <!--                                     style="object-fit: cover;">-->
                            <!--                                <a href=""-->
                            <!--                                   class="overlay align-items-center justify-content-center h4 m-0 text-white text-decoration-none">-->
                            <!--                                    Sports-->
                            <!--                                </a>-->
                            <!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
            <!-- Main News Slider End -->


            <!-- News With Sidebar Start -->
            <div class="container-fluid py-3">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="row mb-3">
                                <div class="col-12">
                                    <div class="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                                        <h3 class="m-0">Popular</h3>
                                        <a class="text-secondary font-weight-medium text-decoration-none" href="">View
                                            All</a>
                                    </div>
                                </div>
                                <div class="row">




                                    <div class="col-lg-6" v-for="item in papularpost.slice(0, 2)" :key="item.id">



                                        <div class="position-relative mb-3">

                                            <img class="img-fluid w-100"
                                                :src="baseImageURL + item.feature_image"
                                                style="object-fit: cover;">
                                            <div class="overlay position-relative bg-light">
                                                <div class="mb-2" style="font-size: 14px;">
                                                    <a href="">{{ item.category.name }}</a>
                                                    <span class="px-1">/</span>
                                                    <span>{{ item.created_at }} </span>
                                                </div>
                                                <router-link  class="h4" :to="'/post/' +item.slug">{{ item.title }}</router-link >
                                                <p class="m-0" v-html="item.content.substring(0,100)+'...'"> </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6" v-for="item in papularpost.slice(2, 6)" :key="item.id">


                                        <div class="d-flex mb-3">

                                            <img :src="baseImageURL + item.feature_image"
                                                style="width: 100px; height: 100px; object-fit: cover;">
                                            <div class="w-100 d-flex flex-column justify-content-center bg-light px-3"
                                                style="height: 100px;">
                                                <div class="mb-1" style="font-size: 13px;">
                                                    <a href="">{{ item.category.name }}</a>
                                                    <span class="px-1">/</span>
                                                    <span>{{ item.created_at }}</span>
                                                </div>
                                                <router-link  class="h6 m-0" :to="'/post/' +item.slug">{{ item.title }}</router-link >
                                            </div>
                                        </div>

                                    </div>





                                </div>

                            </div>

                            <div class="mb-3 pb-3">
                                <a href=""></a>
                                <!-- Place For Ad by Ghulam Ali -->
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                                        <h3 class="m-0">Latest</h3>
                                        <a class="text-secondary font-weight-medium text-decoration-none" href="">View
                                            All</a>
                                    </div>
                                </div>
                                <div class="row">




                                    <div class="col-lg-6" v-for="item in latestpost.slice(0, 2)" :key="item.id">



                                        <div class="position-relative mb-3">

                                            <img class="img-fluid w-100"
                                                :src="baseImageURL + item.feature_image"
                                                style="object-fit: cover;">
                                            <div class="overlay position-relative bg-light">
                                                <div class="mb-2" style="font-size: 14px;">
                                                    <a href="">{{ item.category.name }}</a>
                                                    <span class="px-1">/</span>
                                                    <span>{{ item.created_at }} </span>
                                                </div>
                                                <!-- <router-link  class="h4" :to="'/post/' +item.slug">{{ item.title }}</router-link > -->
                                                <p class="m-0" v-html="item.content.substring(0,100)+'...'"> </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6" v-for="item in latestpost.slice(2, 6)" :key="item.id">


                                        <div class="d-flex mb-3">

                                            <img :src="baseImageURL + item.feature_image"
                                                style="width: 100px; height: 100px; object-fit: cover;">
                                            <div class="w-100 d-flex flex-column justify-content-center bg-light px-3"
                                                style="height: 100px;">
                                                <div class="mb-1" style="font-size: 13px;">
                                                    <a href="">{{ item.category.name  }}</a>
                                                    <span class="px-1">/</span>
                                                    <span>{{ item.created_at }}</span>
                                                </div>
                                                <!-- <router-link  class="h6 m-0" :href="'/post/' +item.slug">{{ item.title }}</router-link > -->
                                            </div>
                                        </div>

                                    </div>





                                </div>
                            </div>
                        </div>

                        <!--   Side Bar  -->


                        <Sidebar/>

                    </div>
                </div>
            </div>

            <!-- News With Sidebar End -->
            <!-- Featured News Slider Start -->
            <div class="container-fluid py-3">
                <div class="container">
                    <div class="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                        <h3 class="m-0">Recommended Blogs</h3>
                        <a class="text-secondary font-weight-medium text-decoration-none" href="">View All</a>
                    </div>
                    <div class="owl-carousel owl-carousel-2 carousel-item-4 position-relative">
                        <div class="position-relative overflow-hidden" style="height: 300px;">
                            <img class="img-fluid w-100 h-100" src="/newtemplate/img/news-300x300-1.jpg"
                                style="object-fit: cover;">
                            <div class="overlay">
                                <div class="mb-1" style="font-size: 13px;">
                                    <a class="text-white" href="">Technology</a>
                                    <span class="px-1 text-white">/</span>
                                    <a class="text-white" href="">January 01, 2045</a>
                                </div>
                                <a class="h4 m-0 text-white" href="">Sanctus amet sed ipsum lorem</a>
                            </div>
                        </div>
                        <div class="position-relative overflow-hidden" style="height: 300px;">
                            <img class="img-fluid w-100 h-100" src="/newtemplate/img/news-300x300-2.jpg"
                                style="object-fit: cover;">
                            <div class="overlay">
                                <div class="mb-1" style="font-size: 13px;">
                                    <a class="text-white" href="">Technology</a>
                                    <span class="px-1 text-white">/</span>
                                    <a class="text-white" href="">January 01, 2045</a>
                                </div>
                                <a class="h4 m-0 text-white" href="">Sanctus amet sed ipsum lorem</a>
                            </div>
                        </div>
                        <div class="position-relative overflow-hidden" style="height: 300px;">
                            <img class="img-fluid w-100 h-100" src="/newtemplate/img/news-300x300-3.jpg"
                                style="object-fit: cover;">
                            <div class="overlay">
                                <div class="mb-1" style="font-size: 13px;">
                                    <a class="text-white" href="">Technology</a>
                                    <span class="px-1 text-white">/</span>
                                    <a class="text-white" href="">January 01, 2045</a>
                                </div>
                                <a class="h4 m-0 text-white" href="">Sanctus amet sed ipsum lorem</a>
                            </div>
                        </div>
                        <div class="position-relative overflow-hidden" style="height: 300px;">
                            <img class="img-fluid w-100 h-100" src="/newtemplate/img/news-300x300-4.jpg"
                                style="object-fit: cover;">
                            <div class="overlay">
                                <div class="mb-1" style="font-size: 13px;">
                                    <a class="text-white" href="">Technology</a>
                                    <span class="px-1 text-white">/</span>
                                    <a class="text-white" href="">January 01, 2045</a>
                                </div>
                                <a class="h4 m-0 text-white" href="">Sanctus amet sed ipsum lorem</a>
                            </div>
                        </div>
                        <div class="position-relative overflow-hidden" style="height: 300px;">
                            <img class="img-fluid w-100 h-100" src="/newtemplate/img/news-300x300-5.jpg"
                                style="object-fit: cover;">
                            <div class="overlay">
                                <div class="mb-1" style="font-size: 13px;">
                                    <a class="text-white" href="">Technology</a>
                                    <span class="px-1 text-white">/</span>
                                    <a class="text-white" href="">January 01, 2045</a>
                                </div>
                                <a class="h4 m-0 text-white" href="">Sanctus amet sed ipsum lorem</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Featured News Slider End -->
        </div>
    </div>
</template>
<script>


import axios from 'axios';

import Sidebar from './Sidebar.vue';

export default {
    name: "Home",
    metaInfo() {
        return {
            title: this.title,
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.description },
                { name: 'title', content: this.meta }
            ],
            link: [
                { rel: 'canonical', href: this.current_url }
            ]
        }
    },
    data(baseImageURL = '') {
        return {
            description: "defualt description",
            meta: 'defualt meta',
            current_url: '/',
            title: 'Home',
            post: [],
            papularpost: [],
            latestpost: [],
            post_single: '',
            categories: [],
            countpopular: 0,
            baseImageURL: baseImageURL,
        }
    },
    components: {
        Sidebar,
    },
    mounted() {
        const vm = this;

        this.baseImageURL = this.$store.state.baseImageURL;
        
        // axios.post(vm.$store.state.baseURL + 'post_slider', {
        //     headers: {}
        // }).then(function (response) {
        //     vm.post = response.data.data;
        //
        // }).catch(error => {
        //     if (error) {
        //         console.log(error);
        //         // vm.loader = 0;
        //     }
        // });
        axios.post(vm.$store.state.baseURL + 'post_slider_latest', {
            headers: {}
        }).then(function (response) {
            vm.post_single = response.data.data;
            console.log(vm.post_single)

        }).catch(error => {
            if (error) {
                console.log(error);
                // vm.loader = 0;
            }
        });


        axios.post(vm.$store.state.baseURL + 'category_sidebar', {
            headers: {}
        }).then(function (response,) {
            vm.categories = response.data.data;

        }).catch(error => {
            if (error) {
                console.log(error);
                // vm.loader = 0;
            }
        });


        this.current_url = this.$store.state.siteURL;
        this.baseImageURL = this.$store.state.baseImageURL;

        axios.post(vm.$store.state.baseURL + 'category/business/post', {
            headers: {}
        }).then(function (response,) {
            vm.papularpost = response.data.data.blog;
            console.log(vm.papularpost)
        }).catch(error => {
            if (error) {
                console.log(error);
                // vm.loader = 0;
            }
        });

        axios.post(vm.$store.state.baseURL + 'latest/post', {
            headers: {}
        }).then(function (response,) {
            vm.latestpost = response.data.data;
            console.log(vm.latestpost)
        }).catch(error => {
            if (error) {
                console.log(error);
                // vm.loader = 0;
            }
        }); 

    },
};

</script>

