<template>
    <div class="col-lg-4 pt-3 pt-lg-0">




        <!-- Ads Start -->
        <!-- <div class="mb-3 pb-3"> -->
        <!-- Ghulam Ali Ad -->
        <!-- <a href=""><img class="img-fluid" src="/newtemplate/img/news-500x280-4.jpg" alt=""></a> -->
        <!-- </div> -->
        <!-- Ads End -->

        <!-- Popular News Start -->
        <div class="pb-3">
            <div class="bg-light py-2 px-4 mb-3">
                <h3 class="m-0">Technology</h3>
            </div>

            <div class="d-flex mb-3" v-for="item in technologyposts.slice(0, 6)" :key="item.id">
                <img :src="baseImageURL+item.feature_image"
                    style="width: 100px; height: 100px; object-fit: cover;">
                <div class="w-100 d-flex flex-column justify-content-center bg-light px-3" style="height: 100px;">
                    <div class="mb-1" style="font-size: 13px;">
                        <a href="">{{ item.category.name }}</a>
                        <span class="px-1">/</span>
                        <span>{{ item.created_at }}</span>
                    </div>
                    <router-link  class="h6 m-0" :to="'/post/' +item.slug">{{ item.title }}</router-link >
                </div>
            </div>

        </div>
        <!-- Popular News End -->


        <!-- Popular News Start -->
        <div class="pb-3">
            <div class="bg-light py-2 px-4 mb-3">
                <h3 class="m-0">Traveling</h3>
            </div>
            <div class="d-flex mb-3" v-for="item in travelposts.slice(0, 6)" :key="item.id">
                <img :src="baseImageURL+item.feature_image"
                    style="width: 100px; height: 100px; object-fit: cover;">
                <div class="w-100 d-flex flex-column justify-content-center bg-light px-3" style="height: 100px;">
                    <div class="mb-1" style="font-size: 13px;">
                        <a href="">{{ item.category.name }}</a>
                        <span class="px-1">/</span>
                        <span>{{ item.created_at }}</span>
                    </div>
                    <router-link  class="h6 m-0" :to="'/post/' +item.slug">{{ item.title }}</router-link >
                </div>
            </div>
            
        </div>
        <!-- Popular News End -->

        <!-- Tags Start -->
        <div class="pb-3">
            <div class="bg-light py-2 px-4 mb-3">
                <h3 class="m-0">Tags</h3>
            </div>
            <div class="d-flex flex-wrap m-n1">
                <a href="" class="btn btn-sm btn-outline-secondary m-1">Politics</a>
                <a href="" class="btn btn-sm btn-outline-secondary m-1">Business</a>
                <a href="" class="btn btn-sm btn-outline-secondary m-1">Corporate</a>
                <a href="" class="btn btn-sm btn-outline-secondary m-1">Sports</a>
                <a href="" class="btn btn-sm btn-outline-secondary m-1">Health</a>
                <a href="" class="btn btn-sm btn-outline-secondary m-1">Education</a>
                <a href="" class="btn btn-sm btn-outline-secondary m-1">Science</a>
                <a href="" class="btn btn-sm btn-outline-secondary m-1">Technology</a>
                <a href="" class="btn btn-sm btn-outline-secondary m-1">Foods</a>
                <a href="" class="btn btn-sm btn-outline-secondary m-1">Entertainment</a>
                <a href="" class="btn btn-sm btn-outline-secondary m-1">Travel</a>
                <a href="" class="btn btn-sm btn-outline-secondary m-1">Lifestyle</a>
            </div>
        </div>
        <!-- Tags End -->
    </div>
</template>


<script>
import axios from 'axios';
export default {
    name: "Sidebar",

    data(baseImageURL = '') {
        return {

            baseImageURL: baseImageURL,
            technologyposts: [],
            travelposts: [],
        }
    },
    components: {
       
    },
    mounted() {

      


        const vm = this;

        this.baseImageURL = this.$store.state.baseImageURL;

        // this.current_url = this.$store.state.siteURL;
        // this.baseImageURL = this.$store.state.baseImageURL;

        axios.post(vm.$store.state.baseURL + 'category/technology/post', {
            headers: {}
        }).then(function (response,) {
            vm.technologyposts = response.data.data.blog;
            console.log(vm.technologyposts)
        }).catch(error => {
            if (error) {
                console.log(error);
                // vm.loader = 0;
            }
        });

        axios.post(vm.$store.state.baseURL + 'category/travel/post', {
            headers: {}
        }).then(function (response,) {
            vm.travelposts = response.data.data.blog;
            console.log(vm.travelposts)
        }).catch(error => {
            if (error) {
                console.log(error);
                // vm.loader = 0;
            }
        });




    },
};


</script>