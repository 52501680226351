<template>
    <div class=" ">
        <Header/>
        <div >
            <main class="c-main">
                <transition name="fade" mode="out-in">
                    <router-view :key="$route.path"></router-view>
                </transition>
            </main>
        </div>



        <Footer/>
    </div>


</template>
<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";


export default {
  name: "Home",
  metaInfo: {
    base: { href: "/" },
    script: [
      {
        src: "https://code.jquery.com/jquery-3.4.1.min.js",
        async: true,
        defer: true,
      },
      {
        src: "https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.bundle.min.js",
        async: true,
        defer: true,
      },

      {
        src: "/newtemplate/lib/owlcarousel/owl.carousel.min.js",
        async: true,
        defer: true,
      },
      {
        src: "/newtemplate/mail/jqBootstrapValidation.min.js",
        async: true,
        defer: true,
      },
      { src: "/newtemplate/mail/contact.js", async: true, defer: true },
      { src: "/newtemplate/js/main.js", async: true, defer: true },
      {
        src: "/newtemplate/lib/easing/easing.min.js",
        async: true,
        defer: true,
      },
    ],

    link: [
      { rel: "stylesheet", href: "https://fonts.gstatic.com" },
      {
        rel: "stylesheet",
        href: "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap",
      },
      {
        rel: "stylesheet",
        href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.0/css/all.min.css",
      },
      {
        rel: "stylesheet",
        href: "/newtemplate/lib/owlcarousel/assets/owl.carousel.min.css",
      },
      { rel: "stylesheet", href: "/newtemplate/css/style.css" },
    ],
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        property: "og:title",
        content: "Test title",
      },
      {
        vmid: "image",
        name: "image",
        content: "image image",
      },
      {
        vmid: "twitter:card",
        name: "twitter:card",
        content: "twitter:card   <sanitized>",
      },
    ],
  },
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
     
  },
};
</script>
<style scoped>
.myBtn {
  right: 55px !important;
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  background-color: black; /* Set a background color */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  border-radius: 10px; /* Rounded corners */
  font-size: 18px; /* Increase font size */
}

.myBtn:hover {
  background-color: #555; /* Add a dark-grey background on hover */
}
</style>
