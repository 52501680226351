<template>
  <div class="container-fluid ghulam-ali-form-container">
      <div class="row">
          <div class="col-lg-8 col-xl-8 left-login">
  
          </div>
          <div class="col-lg-4 col-xl-4 right-login">
          
              <h2>Login</h2>
              <h3>Welcome to Blog Verse</h3>

              <p style="background-color: red;border-radius: 5px ;padding: 5px 10px;" v-if="errorMessage !== null">{{
          errorMessage }}</p>

            <form  >
                  
                  <input type="email" name="email" id="email" placeholder="Enter Email"  v-model="form.email">
                  <input type="password" name="password" id="password" placeholder="Enter Password" v-model="form.password">
  
                  <button type="button"  v-on:click="sentLogin()">Login</button>
  
                 <div class="secondry-links">
                  <a href="#">Forget Password</a> - <router-link :to="'/register'">Create an account</router-link>
              </div>
            </form>
          </div>
      </div>
  </div>
  </template>
<script>
import axios from "axios";

export default {
  name: "login",
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        { name: "description", content: this.description },
        { name: "title", content: this.meta },
      ],
      link: [{ rel: "canonical", href: this.current_url }],
    };
  },
  data(baseImageURL = "") {
    return {
      description: "defualt description",
      meta: "defualt meta",
      current_url: "/",
      title: "Contact Us",
      text: "",
      image: "",
      baseImageURL: baseImageURL,
      errorMessage: null,
      form: {
        email: "",
        password: "",
        
      },
    };
  },
  components: {},
  methods: {
   sentLogin() {
      console.log(this.form);
      const vm = this;
      event.preventDefault();
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios
        .post(this.$store.state.baseURL + "auth/login", {
          email: this.form.email,
          password: this.form.password,
          headers: {
            // remove headers
          },
        })
        .then(function (response) {
          console.log(response);
          if (response.data.data.token) {
            // alert(7777);
            localStorage.setItem("user-token", response.data.data.token);
            // const token = localStorage.getItem('user-token');
            vm.$store.state.acesstoken = response.data.data.token;
            vm.$router.push("admin/dashboardys");
          } else {
            this.error = "Some thing wrong try again...";
          } 
        })
        .catch((error) => {
          if (error) {
            console.log(error);
            if (error.response && error.response.status === 301) {
            this.errorMessage="Login Failed";
            setTimeout(() => {
          this.errorMessage = null;
        }, 5000);
      }

          }
        });
    },
  },
  mounted() {
    this.current_url = this.$store.state.siteURL + this.$route.path;
  },
};
</script>
