<template>
    <div>
        <!-- Topbar Start -->
        <div class="container-fluid">
            <div class="row align-items-center bg-light px-lg-5">
                <div class="col-12 col-md-8">
                    <div class="d-flex justify-content-between">
                        <div
                                class="bg-primary text-white text-center py-2"
                                style="width: 100px"
                        >
                            Tranding
                        </div>
                        <div
                                class="owl-carousel owl-carousel-1 tranding-carousel position-relative d-inline-flex align-items-center ml-3"
                                style="width: calc(100% - 100px); padding-left: 90px"
                        >
                            <div class="text-truncate">
                                <a class="text-secondary" href=""
                                >Labore sit justo amet eos sed, et sanctus dolor diam eos</a
                                >
                            </div>
                            <div class="text-truncate">
                                <a class="text-secondary" href=""
                                >Gubergren elitr amet eirmod et lorem diam elitr, ut est erat
                                    Gubergren elitr amet eirmod et lorem diam elitr, ut est
                                    erat</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 text-right d-none d-md-block">
                    <div class="btns-auth" style="display: flex;gap: 10px;justify-content: end;align-items: center;">
                        <router-link :to="'/login'" class="login-btn">Login</router-link>
                        <router-link :to="'/register'" class="register-btn">Register</router-link>
                    </div>
                </div>
            </div>
            <div class="row align-items-center py-2 px-lg-5">
                <div class="col-lg-4">
                    <a href="" class="navbar-brand d-none d-lg-block">
                        <h1 class="m-0 display-5 text-uppercase">
                            <span class="text-primary">News</span>Room
                        </h1>
                    </a>
                </div>
                <div class="col-lg-8 text-center text-lg-right">
                    <!-- Place For Ad by Ghulam Ali -->
                </div>
            </div>
        </div>
        <!-- Topbar End -->

        <!-- Navbar Start -->
        <div class="container-fluid p-0 mb-3">
            <nav
                    class="navbar navbar-expand-lg bg-light navbar-light py-2 py-lg-0 px-lg-5"
            >
                <a href="" class="navbar-brand d-block d-lg-none">
                    <h1 class="m-0 display-5 text-uppercase">
                        <span class="text-primary">News</span>Room
                    </h1>
                </a>
                <button
                        type="button"
                        class="navbar-toggler"
                        data-toggle="collapse"
                        data-target="#navbarCollapse"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div
                        class="collapse navbar-collapse justify-content-between px-0 px-lg-3"
                        id="navbarCollapse"
                >
                    <div class="navbar-nav mr-auto py-0">
                        <router-link :to="'/'" class="nav-item nav-link active">Home</router-link>
                        <!--                        <a href="#" class="nav-item nav-link">About</a>-->

                        <div v-for="category in categories" :key="category.id" class="nav-item dropdown">
                            <a :href="'category/'+category.slug" class="nav-link dropdown-toggle"
                               data-toggle="dropdown">{{ category.name }}</a>


                            <div class="dropdown-menu rounded-0 m-0">

                                <router-link v-for="sub_category in category.sub_category" :key="sub_category.id"
                                             :to="'subcategory/'+sub_category.slug" class="dropdown-item">

                                    {{
                                    sub_category.name
                                    }}

                                </router-link>
                            </div>
                        </div>
                        <router-link :to="'contact'" class="nav-item nav-link">Contact</router-link>
                    </div>
                    <div
                            class="input-group ml-auto"
                            style="width: 100%; max-width: 300px"
                    >
                        <input type="text" class="form-control" placeholder="Keyword"/>
                        <div class="input-group-append">
                            <button class="input-group-text text-secondary">
                                <i class="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
        <!-- Navbar End -->
    </div>
</template>
<script>
    import axios from 'axios';

    export default {
        props: {
            // msg: String,
        },
        data(baseImageURL = "") {
            return {
                categories: [],
                baseImageURL: baseImageURL,
            };
        },
        mounted() {
            const vm = this;
            axios.post(vm.$store.state.baseURL + 'category', {
                headers: {}
            }).then(function (response,) {
                vm.categories = response.data.data;

            }).catch(error => {
                if (error) {
                    console.log(error);
                    // vm.loader = 0;
                }
            });

        },
    };
</script>
