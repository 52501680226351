<template>
  <div class="container-fluid">
    <div class="row my-4">


      <div v-for="item in categories" :key="item.id" class="col-lg-4 col-md-4">
        <router-link :to="{ name: 'subcategories', params: { categoryId: item.id } }">
          <div class="ghulam-ali-categorey-card shadow">
            <img :src="'http://127.0.0.1:8000/'+item.feature_image"
              alt="">

            <h2>{{ item.name }}</h2>
            <p>{{ item.description }}</p>

          </div>
        </router-link>
      </div>

    </div>
  </div>
</template>
<script>
// import axios from 'axios';

// import Sidebar from './Sidebar.vue';
// import NewsLetter from './NewsLetter.vue';
import axios from 'axios';
export default {
  name: "Home",
  metaInfo() {
    return {
      title: 'Category - ' + this.category,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
        { name: 'title', content: this.meta }
      ],
      link: [
        { rel: 'canonical', href: this.current_url }
      ]
    }
  },
  data(baseImageURL = '') {
    return {
      description: "defualt description",
      meta: 'defualt meta',
      current_url: '/',
      categories: [],
      subcategory: [],
      baseImageURL: baseImageURL,
    }
  },
  components: {
    // Sidebar,
    // NewsLetter
  },
  mounted() {
    const vm = this;
    axios.post(vm.$store.state.baseURL + 'all_category', {
      headers: {}
    }).then(function (response,) {
      vm.categories = response.data.data;


    }).catch(error => {
      if (error) {
        console.log(error);
        // vm.loader = 0;
      }
    });

  },
};
</script>
<style>
.ghulam-ali-categorey-card {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
}

.ghulam-ali-categorey-card img {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
}</style>
