

<template>
  <div class="container-fluid ghulam-ali-form-container">
    <div class="row">
      <div class="col-lg-8 left-login">

      </div>
      <div class="col-lg-4 right-login">

        <h2>Register</h2>
        <h3>Welcome to Blog Verse</h3>

        <p style="background-color: red;border-radius: 5px ;padding: 5px 10px;" v-if="errorMessage !== null">{{
          errorMessage }}</p>
        <p style="background-color: rgb(14, 223, 83);border-radius: 5px ;padding: 5px 10px;"
          v-if="successMessage !== null">{{ successMessage }}</p>
        <form>
          <input type="text" name="name" id="name" placeholder="Enter Name" v-model="name">
          <input type="email" name="email" id="email" placeholder="Enter Email" v-model="email">

          <input type="password" name="password" id="password" placeholder="Enter Password" v-model="password">

          <button v-on:click="getRegistered()" type="button">Register</button>

          <div class="secondry-links">
            <router-link :to="'/login'">Already an account</router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style></style>
<script>
import axios from 'axios';



export default {

  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        { name: "description", content: this.description },
        { name: "title", content: this.meta },
      ],
      link: [{ rel: "canonical", href: this.current_url }],
    };
  },
  data(baseImageURL = '') {
    return {

      current_url: '/',
      baseImageURL: baseImageURL,
      name: "",
      email: "",
      password: "",
      successMessage: null,
      errorMessage: null,

    }
  },
  components: {

  },
  methods: {
    getRegistered() {


      // alert(2)

      axios.post(this.$store.state.baseURL + 'auth/register', {
        // Data to be sent in the request body
        name: this.name,
        email: this.email,
        password: this.password,


        // Add more key-value pairs as needed
      }, {
        headers: {
          // Specify headers if required

        }
      }).then((response) => {


        console.log("Success register = " + response);
        if (response && response.status === 200) {
          this.errorMessage = null;
          this.successMessage = "Successfully Registered";
          setTimeout(() => {
            this.successMessage = null;
          }, 5000);
        }

      }).catch((error) => {
        if (error.response && error.response.status === 302) {
          this.errorMessage = "Email Already Exists";
        }
        setTimeout(() => {
          this.errorMessage = null;
        }, 5000);


      });
    }

  },
  mounted() {
    this.baseImageURL = this.$store.state.baseImageURL;
    this.current_url = this.$store.state.siteURL;

  },

};
</script>