<template>
  <div class="container-fluid">
    <div class="row my-4">


      <div class="col-lg-4 col-md-4">

          <div class="ghulam-ali-categorey-card shadow">
            <img
                 alt="">

            <h2>test</h2>
            <p>test</p>

          </div>

      </div>

    </div>
  </div>
</template>
<script>
import axios from 'axios';

// import Sidebar from "./Sidebar.vue";
// import NewsLetter from "./NewsLetter.vue";

export default {
    name: "Home",
    metaInfo() {
        return {
            title: 'SubCategory - ' + this.category,
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.description },
                { name: 'title', content: this.meta }
            ],
            link: [
                { rel: 'canonical', href: this.current_url }
            ]
        }
    },
    data(baseImageURL = '') {
        return {
            description: "defualt description",
            meta: 'defualt meta',
            current_url: '/',
            category: '',
            subcategory: '',
            baseImageURL: baseImageURL,
        }
    },
    components: {
        // Sidebar,
        // NewsLetter
    },
    mounted() {
      const vm = this;
      axios.post(vm.$store.state.baseURL + 'sub_category/', + this.categoryId,{
        headers: {}
      }).then(function (response,) {
        vm.subcategory = response.data.data;
        console.log(vm.subcategory)


      }).catch(error => {
        if (error) {
          console.log(error);
          // vm.loader = 0;
        }
      });

    },
};
</script>
